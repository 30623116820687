<template>
  <b-modal id="modal-comment" title="Note to Administration" @show="loadData()" hide-footer>
    <b-form class="text-center">
      <b-form-textarea
        v-model="newComment.comment"
        placeholder="Enter note..."
        rows="3"
        max-rows="8"
        class="mb-2 mr-sm-2 mb-sm-0"
      ></b-form-textarea>
      <h5 class="mt-3">Frequency</h5>
      <b-form-group label>
        <!-- Select Days of Week before or after -->
        <b-form-checkbox-group
          v-model="weekDaysDeltaSelected"
          :options="optionsWeekDays"
          buttons
          stacked
          button-variant="outline-secondary"
          size="sm"
          name="week-days-delta"
        ></b-form-checkbox-group>
        <!-- Select Before or After -->
        <b-form-radio-group
          v-model="deltaSelected"
          :options="optionsDelta"
          :disabled="isWeekDaysDeltaSelectedEmpty"
          buttons
          stacked
          class="ml-1"
          button-variant="outline-secondary"
          size="sm"
          name="delta"
        ></b-form-radio-group>
        <!-- Select Weeks 1-5 -->
        <b-form-checkbox-group
          v-model="weeksSelected"
          :options="optionsWeeks"
          buttons
          stacked
          class="ml-2"
          button-variant="outline-info"
          size="sm"
          name="weeks"
        ></b-form-checkbox-group>
        <!-- Select days of the week -->
        <b-form-checkbox-group
          v-model="weekDaysSelected"
          :options="optionsWeekDays"
          buttons
          stacked
          class="ml-1"
          button-variant="outline-info"
          size="sm"
          name="week-days"
        ></b-form-checkbox-group>
        <!-- Select Months Jan-Jun -->
        <b-form-checkbox-group
          v-model="monthsSelected"
          :options="optionsMonths1"
          buttons
          stacked
          class="ml-2"
          button-variant="outline-info"
          size="sm"
          name="months"
        ></b-form-checkbox-group>
        <!-- Select Months Jul-Dec -->
        <b-form-checkbox-group
          v-model="monthsSelected"
          :options="optionsMonths2"
          buttons
          stacked
          button-variant="outline-info"
          size="sm"
          name="months"
        ></b-form-checkbox-group>
      </b-form-group>
      <div>
        <h5 class="text-danger">{{ error }}</h5>
        <p>
          <small class="text-muted">
            Note: Usually you should ignore the first two columns in gray.
            You will need them only when you want to say something like
            <span
              class="font-italic"
            >Monday before 3rd Wednesday</span>. Unfortunately,
            you can’t create a recurrent note by day of the month e.g. July 4th
          </small>
        </p>
      </div>
      <!-- Buttons -->
      <div class="text-center">
        <b-button ref="save" @click="onSave()" variant="primary">Save</b-button>
        <b-button class="ml-1" @click="onCancel()" variant="warning">Cancel</b-button>
        <b-button class="ml-1" @click="onDelete()" variant="danger">Delete</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: { originalComment: Object },
  data: function() {
    return {
      newComment: {
        id: 0,
        comment: "",
        weekDaysDelta: 0, // first is before/after, rest: Sun to Sat
        weeks: 0, // 1st - 5th week
        weekDays: 0, // Sun to Sat
        months: 4095, // Jan to Dec
        notes: ""
      },
      error: "",
      weekDaysDeltaSelected: [],
      weekDaysSelected: [],
      deltaSelected: 0,
      weeksSelected: [],
      monthsSelected: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048],
      optionsWeekDays: [
        { text: "Sun", value: 1 },
        { text: "Mon", value: 2 },
        { text: "Tue", value: 4 },
        { text: "Wed", value: 8 },
        { text: "Thu", value: 16 },
        { text: "Fri", value: 32 },
        { text: "Sat", value: 64 }
      ],
      optionsDelta: [
        { text: "before", value: 128 },
        { text: "after", value: 256 }
      ],
      optionsWeeks: [
        { text: "1st", value: 1 },
        { text: "2nd", value: 2 },
        { text: "3th", value: 4 },
        { text: "4th", value: 8 },
        { text: "5th", value: 16 }
      ],
      optionsMonths1: [
        { text: "Jan", value: 1 },
        { text: "Feb", value: 2 },
        { text: "Mar", value: 4 },
        { text: "Apr", value: 8 },
        { text: "May", value: 16 },
        { text: "Jun", value: 32 }
      ],
      optionsMonths2: [
        { text: "Jul", value: 64 },
        { text: "Ago", value: 128 },
        { text: "Sep", value: 256 },
        { text: "Oct", value: 512 },
        { text: "Nov", value: 1024 },
        { text: "Dec", value: 2048 }
      ]
    };
  },
  computed: {
    isWeekDaysDeltaSelectedEmpty() {
      return this.weekDaysDeltaSelected.length == 0;
    }
  },
  components: {},
  watch: {
    weekDaysDeltaSelected() {
      if (this.weekDaysDeltaSelected.length == 0) {
        this.deltaSelected = 0;
      } else {
        if (this.deltaSelected == 0) {
          this.deltaSelected = 128;
        }
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    populateArray(options, n) {
      let selected = [];
      let i = 0;
      while (n > 0) {
        if (n & 1) {
          selected.push(options[i].value);
        }
        i++;
        n = n >> 1;
      }
      return selected;
    },
    loadData() {
      this.error = "";
      this.newComment = { ...this.originalComment };
      if (this.newComment.weekDaysDelta & 128) {
        this.deltaSelected = 128;
      }
      if (this.newComment.weekDaysDelta & 256) {
        this.deltaSelected = 256;
      }
      this.weekDaysDeltaSelected = this.populateArray(
        this.optionsWeekDays,
        this.newComment.weekDaysDelta & 127
      );
      this.weeksSelected = this.populateArray(
        this.optionsWeeks,
        this.newComment.weeks
      );
      this.weekDaysSelected = this.populateArray(
        this.optionsWeekDays,
        this.newComment.weekDays
      );
      this.monthsSelected = this.populateArray(
        this.optionsMonths1.concat(this.optionsMonths2),
        this.newComment.months
      );
    },
    noErrors() {
      this.error = "";
      if (!this.newComment.weeks) {
        this.error = "At least one week should be selected";
      }
      if (!this.newComment.weekDays) {
        this.error = "At least one day of the week should be selected";
      }
      if (!this.newComment.months) {
        this.error = "At least one month should be selected";
      }
      if (!this.newComment.comment) {
        this.error = "You are forgetting to write the comment!";
      }
      return this.error.length == 0;
    },
    populateNewComment() {
      this.newComment.weekDaysDelta = this.weekDaysDeltaSelected.reduce(
        (tot, i) => tot + i,
        0
      );
      this.newComment.weekDaysDelta += this.deltaSelected;
      this.newComment.weeks = this.weeksSelected.reduce((tot, i) => tot + i, 0);
      this.newComment.weekDays = this.weekDaysSelected.reduce(
        (tot, i) => tot + i,
        0
      );
      this.newComment.months = this.monthsSelected.reduce(
        (tot, i) => tot + i,
        0
      );
    },
    onSave() {
      this.populateNewComment();
      if (this.noErrors()) {
        let url = "updateRecurrentComment.php";
        this.$http
          .post(url, this.newComment, { emulateJSON: true })
          .then(() => {
            this.$emit("comment-edited");
            // this. = res.body
          });
        this.$bvModal.hide("modal-comment");
      }
    },
    onCancel() {
      this.$bvModal.hide("modal-comment");
    },
    onDelete() {
      let url = "deleteComment.php";
      let data = { id: this.newComment.id };
      this.$http.post(url, data, { emulateJSON: true }).then(() => {
        // this. = res.body
        this.$emit("comment-edited");
      });
      this.$bvModal.hide("modal-comment");
    }
  }
};
</script>

<style lang="scss">
.btn-outline-info:hover {
  /* color: #fff; */
  background-color: white;
  color: #17a2b8;
}
.btn-outline-secondary:hover {
  /* color: #fff; */
  background-color: white;
  color: #6c757d;
}
p {
  line-height: 1.2;
}
</style>
