<template>
  <div>
    <b-modal id="modal-actual-comment" :title="dateFormat" @show="loadData()" hide-footer>
      <b-form v-on:submit.prevent="onSubmit">
        <b-textarea v-model="newComment.text"></b-textarea>
      </b-form>
      <!-- Buttons -->
      <div class="text-center mt-4">
        <b-button @click="onSubmit()" variant="primary">Save</b-button>
        <b-button class="ml-1" @click="onCancel()" variant="warning">Cancel</b-button>
        <b-button class="ml-1" @click="onDelete()" variant="danger">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: { comment: Object },
  data: function() {
    return {
      newComment: { date: "", text: "" }
    };
  },
  computed: {
    dateFormat: function() {
      return moment(this.newComment.date).format("dddd, MMMM D");
    }
  },
  components: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {
    onSubmit() {
      this.$emit("actual-comment-edited", this.newComment);
      this.$bvModal.hide("modal-actual-comment");
    },
    onCancel: function() {
      this.$bvModal.hide("modal-actual-comment");
    },
    onDelete() {
      this.$emit("actual-comment-deleted", this.newComment);
      this.$bvModal.hide("modal-actual-comment");
    },
    loadData: function() {
      this.newComment.date = this.comment.date;
      this.newComment.text = this.comment.text;
    }
  }
};
</script>

<style lang="scss">
</style>
