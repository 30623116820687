<template>
  <div class="mt-3">
    <h4>Settings</h4>
    <comments-edit v-bind:originalComment="commentToEdit" v-on:comment-edited="getListComments()" />
    <comments-actual
      v-bind:comment="actualCommentToEdit"
      v-on:actual-comment-edited="actualCommentEdited"
      v-on:actual-comment-deleted="actualCommentDeleted"
    />

    <!-- Defaults comments for feast days & birthdays -->
    <div class="defaults mt-3 mb-5 pt-3 pb-3 border-top border-bottom">
      <div class="text-left">
        <h5>Default Notes on Feast Days & Birthdays</h5>
        <b-input-group class="mt-3" prepend="A">
          <b-form-input v-model="defaultsComments.a"></b-form-input>
        </b-input-group>
        <b-input-group class="mt-1" prepend="B">
          <b-form-input v-model="defaultsComments.b"></b-form-input>
        </b-input-group>
        <b-input-group class="mt-1" prepend="C">
          <b-form-input v-model="defaultsComments.c"></b-form-input>
        </b-input-group>

        <b-input-group class="mt-2" prepend="Birthdays">
          <b-form-input v-model="defaultsComments.birthday.text"></b-form-input>
        </b-input-group>
        <div class="text-muted small">
          Birthdays will be added also as an event in the Calendar view.
          In the Notes, the optional
          <span
            class="font-weight-bold"
          >%Initials%</span> string will be replaced by the actual initials.
        </div>
      </div>
      <b-button @click="saveDefaultComments()" class="mt-3" variant="primary">Save New Defaults</b-button>
    </div>

    <!-- List of Recurrent Comments -->
    <h5 class="text-left mt-5 mb-0">Recurrent Notes [BANK]</h5>
    <div class="text-left small text-muted">
      These entries are only
      <span class="font-italic">templates</span>.
      The actual notes will be added when clicking on the yellow button
      <span
        class="font-weight-bold"
      >[Add Notes]</span> month by month.
      See the list displayed at the bottom of this page.
    </div>
    <b-table
      class="table mt-3"
      ref="comments"
      striped
      small
      hover
      :items="listComments"
      :fields="fields"
      :key="listComments.id"
      selectable
      select-mode="single"
      v-on:row-selected="rowSelected($event)"
    >
      <template v-slot:cell(Provider)="data">{{ data.value ? "Yes":"No" }}</template>
    </b-table>
    <b-button class="mb-5" @click="addNewComment()" variant="primary">New Recurrent Note</b-button>

    <!-- Adding recurring comments to especific month -->
    <div class="pt-3 text-left">
      <h5>Recurrent Notes [APPLY]</h5>
      <div class="small text-muted mb-5">
        <p></p>
        <h6>STEP 1: Select Month & Year</h6>
        <b-row no-gutters>
          <b-col cols="auto">
            <b-form-select v-model="monthToDeploy" :options="optionsMonths"></b-form-select>
          </b-col>
          <b-col cols="auto">
            <b-form-select v-model="yearToDeploy" :options="optionsYears"></b-form-select>
          </b-col>
        </b-row>
        <h6 class="mt-4">STEP 2: Edit any note from the list if necessary.</h6>
        <p style="muted">
          This list is compiled automatically from
          the
          <span
            class="font-italic"
          >Recurrent Notes [BANK]</span>, and
          <span class="font-italic">Default Notes</span>.
        </p>
        <div>
          <b-table
            class="table2 mt-3"
            ref="actualcomments"
            striped
            small
            hover
            :items="listNewComments"
            :fields="fieldsNewComments"
            :key="listNewComments.date"
            sortBy="date"
            selectable
            select-mode="single"
            v-on:row-selected="rowActualCommentSelected($event)"
          ></b-table>
        </div>
        <div class="text-right mb-2">
          <b-button @click="updateListNewComments()" size="sm" variant="info">Undo Changes</b-button>
        </div>
        <h6 class="mt-4">STEP 3: Click on 'Add Notes'</h6>
        <div class="text-center mb-2">
          <b-button @click="seveListDeployCommentsAndBirthdays()" variant="warning">Add Notes</b-button>
        </div>

        <p>
          By clicking in the
          <span class="font-weight-bold">[Add Notes]</span> button
          these Notes will be added to this especific month.
        </p>
      </div>

      
    </div>
  </div>
</template>

<script>
import CommentsEdit from "./CommentsEdit.vue";
import CommentsActual from "./CommentsActual.vue";
import moment from "moment";

export default {
  props: [],
  data: function() {
    return {
      listComments: [],
      listNewComments: [],
      listCommentsFeastsAndBirthdays: [],
      defaultsComments: {
        a: "",
        b: "",
        c: "",
        birthday: {
          text: "",
          color: "#00ffcc"
        }
      },
      commentToEdit: { id: 0, comment: "" },
      actualCommentToEdit: { date: "", text: "" },
      fieldsNewComments: [
        {
          key: "date",
          label: "Date",
          // eslint-disable-next-line no-unused-vars
          formatter: (v, k, i) => moment(v).format("ddd. D")
        },
        { key: "text", label: "Note" }
      ],
      fields: [
        // { key: "id", label: "id", sortable: true },
        { key: "Comment", label: "Comment" },
        {
          key: "WeekDaysDelta",
          label: "Ref.",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.formatWeekDayDelta(value);
          }
        },
        {
          key: "Weeks",
          label: "Weeks",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.formatWeek(value);
          }
        },
        {
          key: "WeekDays",
          label: "Days",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.formatWeekDay(value);
          }
        },
        {
          key: "Months",
          label: "Months",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.formatMonth(value);
          }
        }
      ],
      monthToDeploy: moment().month(),
      yearToDeploy: moment().year(),
      optionsMonths: [
        { text: "January", value: 0 },
        { text: "February", value: 1 },
        { text: "March", value: 2 },
        { text: "April", value: 3 },
        { text: "May", value: 4 },
        { text: "June", value: 5 },
        { text: "July", value: 6 },
        { text: "August", value: 7 },
        { text: "September", value: 8 },
        { text: "October", value: 9 },
        { text: "November", value: 10 },
        { text: "December", value: 11 }
      ],
      optionsYears: [
        { text: moment().format("YYYY"), value: moment().format("YYYY") },
        {
          text: moment()
            .add(1, "y")
            .format("YYYY"),
          value: moment()
            .add(1, "y")
            .format("YYYY")
        },
        {
          text: moment()
            .add(2, "y")
            .format("YYYY"),
          value: moment()
            .add(2, "y")
            .format("YYYY")
        },
        {
          text: moment()
            .add(3, "y")
            .format("YYYY"),
          value: moment()
            .add(3, "y")
            .format("YYYY")
        }
      ]
    };
  },
  computed: {},
  components: {
    CommentsEdit,
    CommentsActual
  },
  watch: {
    listComments: function() {
      this.updateListNewComments();
    },
    monthToDeploy: function() {
      this.updateListNewComments();
    },
    yearToDeploy: function() {
      this.updateListNewComments();
    }
  },
  mounted() {
    this.getListComments();
    this.getDefaultComments();
  },
  created() {},
  methods: {
    getElementsArray(a, n, st) {
      let sp = "";
      let i = 0;
      while (n > 0) {
        if (n & 1) {
          st = st + sp + a[i];
        }
        if (st.length) {
          sp = ", ";
        }
        n = n >> 1;
        i++;
      }
      return st;
    },
    formatWeek(weeks) {
      const a = ["1st", "2nd", "3rd", "4th", "5th"];
      return this.getElementsArray(a, weeks, "");
    },
    formatWeekDay(weekDays) {
      const a = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return this.getElementsArray(a, weekDays, "");
    },
    formatWeekDayDelta(weekDaysDelta) {
      const a = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let st = "";
      if (weekDaysDelta > 0) {
        st = weekDaysDelta > 256 ? " after" : " before";
      }
      weekDaysDelta = weekDaysDelta & 127;
      return this.getElementsArray(a, weekDaysDelta, "") + st;
    },
    formatMonth(months) {
      const a = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      if (months == 4095) {
        return "Every month";
      } else {
        return this.getElementsArray(a, months, "");
      }
    },
    getListComments() {
      var url = "getListComments.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.listComments = res.body;
      });
    },
    getDefaultComments() {
      let url = "getDefaultComments.php";
      this.$http.post(url, {}, { emulateJSON: true }).then(res => {
        this.defaultsComments.a = res.body.DefaultCommentFeastA;
        this.defaultsComments.b = res.body.DefaultCommentFeastB;
        this.defaultsComments.c = res.body.DefaultCommentFeastC;
        this.defaultsComments.birthday.text = res.body.DefaultCommentBirthday;
      });
    },
    addNewComment() {
      this.commentToEdit.id = 0;
      this.commentToEdit.comment = "";
      this.commentToEdit.weekDaysDelta = 0;
      this.commentToEdit.weeks = 0;
      this.commentToEdit.weekDays = 0;
      this.commentToEdit.months = 4095;
      this.commentToEdit.notes = "";
      this.$bvModal.show("modal-comment");
    },
    rowSelected(e) {
      if (e.length > 0) {
        this.commentToEdit.id = e[0].id;
        this.commentToEdit.comment = e[0].Comment;
        this.commentToEdit.weekDaysDelta = e[0].WeekDaysDelta;
        this.commentToEdit.weeks = e[0].Weeks;
        this.commentToEdit.weekDays = e[0].WeekDays;
        this.commentToEdit.months = e[0].Months;
        this.commentToEdit.notes = e[0].Notes;
        this.$bvModal.show("modal-comment");
      }
    },
    rowActualCommentSelected(e) {
      if (e.length > 0) {
        this.actualCommentToEdit.date = e[0].date;
        this.actualCommentToEdit.text = e[0].text;
        this.$bvModal.show("modal-actual-comment");
      }
    },
    getListDeployComments() {
      const year = this.yearToDeploy;
      const month = this.monthToDeploy;
      let commentsToDepoly = [];

      function pushElementsArray(a, n) {
        let newArray = [];
        let i = 0;
        while (n > 0) {
          if (n & 1) {
            newArray.push(a[i]);
          }
          n = n >> 1;
          i++;
        }
        return newArray;
      }

      function addDelta(date, weekDay, el) {
        let weekDaysDeltaAll = [];
        let weekDaysDeltaN = el.WeekDaysDelta & 127;
        if (el.WeekDaysDelta > 0) {
          weekDaysDeltaAll = pushElementsArray(
            [0, 1, 2, 3, 4, 5, 6],
            weekDaysDeltaN
          );
          weekDaysDeltaAll.forEach(wd => {
            let delta = 0;
            if (el.WeekDaysDelta > 256) {
              // is after
              delta = wd >= weekDay ? wd - weekDay : wd + 7 - weekDay;
            } else {
              // is before
              delta = weekDay >= wd ? wd - weekDay : wd - 7 - weekDay;
            }
            let dateDelta = moment(date);
            commentsToDepoly.push({
              date: dateDelta.add(delta, "days").format("YYYY-MM-DD"),
              text: el.Comment
            });
          });
        }
      }

      function deployWeekDay(week, weekDay, el) {
        let date = moment()
          .startOf("month")
          .year(year)
          .month(month)
          .date(1);
        const firstWeekDay = date.day();
        let n = firstWeekDay > weekDay ? 7 : 0;
        date.day(weekDay + n);
        date.add((week - 1) * 7, "d");
        if (date.month() == month) {
          if (el.WeekDaysDelta > 0) {
            addDelta(date, weekDay, el);
          } else {
            commentsToDepoly.push({
              date: date.format("YYYY-MM-DD"),
              text: el.Comment
            });
          }
        }
      }

      function deployWeek(week, el) {
        el.dates = [];
        const weekDaysAll = pushElementsArray(
          [0, 1, 2, 3, 4, 5, 6],
          el.WeekDays
        );
        weekDaysAll.forEach(weekDay => deployWeekDay(week, weekDay, el));
      }

      function deployMonth(el) {
        const weeksAll = pushElementsArray([1, 2, 3, 4, 5], el.Weeks);
        weeksAll.forEach(week => deployWeek(week, el));
      }

      function thereAreEventsMonth(el) {
        let monthsAll = pushElementsArray(
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          el.Months
        );
        return monthsAll.indexOf(month) >= 0;
      }

      function deployComment(el) {
        if (thereAreEventsMonth(el)) {
          deployMonth(el);
        }
      }

      this.listComments.forEach(el => deployComment(el));

      return commentsToDepoly;
    },
    seveListDeployCommentsAndBirthdays() {
      this.seveListDeployComments();
      this.saveDoployBirthdays();
    },
    seveListDeployComments() {
      let url = "deployComments.php";
      let data = { data: this.listNewComments };
      this.$http.post(url, data, { emulateJSON: true }).then(() => {
        // this. = res.body
        this.$bvToast.toast(`Comments added to Calendar`, {
          title: "Comments",
          variant: "info",
          toaster: "b-toaster-top-center",
          autoHideDelay: 2000,
          appendToast: false
        });
      });
    },
    saveDoployBirthdays() {
      let url = "deployBirthdays.php";
      let data = { month: this.monthToDeploy, year: this.yearToDeploy };
      this.$http.post(url, data, { emulateJSON: true }).then(() => {});
    },
    updateListNewComments() {
      this.listNewComments = this.getListDeployComments();
      this.addListBirthdaysAndFeasts();
    },
    elimanteDuplicatesFromList(myArray) {
      let mapComments = new Map();
      myArray.forEach(function(el) {
        if (mapComments.get(el.date)) {
          mapComments.set(el.date, mapComments.get(el.date) + " - " + el.text);
        } else {
          mapComments.set(el.date, el.text);
        }
      });
      myArray = [];
      mapComments.forEach(function(value, key) {
        myArray.push({ date: key, text: value });
      });
      return myArray;
    },
    addListBirthdaysAndFeasts() {
      let url = "getListBirthdyasAndFeasts.php";
      let data = { month: this.monthToDeploy, year: this.yearToDeploy };
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.listCommentsFeastsAndBirthdays = res.body.Comments;
        this.listNewComments = [
          ...this.listNewComments,
          ...this.listCommentsFeastsAndBirthdays
        ];
        this.listNewComments = this.elimanteDuplicatesFromList(
          this.listNewComments
        );
      });
    },
    saveDefaultComments() {
      let url = "saveDefaultComments.php";
      this.$http
        .post(url, this.defaultsComments, { emulateJSON: true })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // this. = res.body
          this.updateListNewComments();
          this.$bvToast.toast(`The new Defaults have been saved`, {
            title: "Default Comments",
            variant: "",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false
          });
        });
    },
    actualCommentEdited(comment) {
      this.listNewComments.forEach(function(v) {
        if (v.date == comment.date) {
          v.text = comment.text;
        }
      });
    },
    actualCommentDeleted(comment) {
      let toDelete = this.listNewComments.findIndex(
        el => el.date == comment.date
      );
      if (toDelete >= 0) {
        this.listNewComments.splice(toDelete, 1);
      }
    }
  }
};
</script>

<style lang="scss">
.table {
  font-size: 0.7em;
}
.table2 {
  font-size: 0.8em;
}
p {
  line-height: 1.2;
}
.defaults {
  background-color: #f8fcff;
}
</style>
